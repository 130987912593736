var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("section", { staticClass: "pr-4" }, [
    _c(
      "div",
      { staticClass: "m-1 mr-4 px-0" },
      [_c("breadcrumb", { attrs: { title: "BI", actualPage: "Integrado" } })],
      1
    ),
    _c("div", [
      this.loading
        ? _c(
            "div",
            { staticClass: "pt-10", attrs: { id: "wait_loading_embbeded" } },
            [_c("h4", [_vm._v("Aguarde, vamos carregar o BI...")])]
          )
        : _vm._e(),
      _c("div", {
        staticStyle: { width: "100%", height: "1620px" },
        attrs: { id: "embedContainer" }
      })
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }